import {customElement, property, queryAssignedElements} from 'lit/decorators.js';
import {html, LitElement} from "lit";
import {FormValidationMixin} from "@/components/mixins/form-validation-mixin";
import {SbkLabelMixin} from "@/components/label/label-mixin";
import {FormControlDependencyMixin} from "@/components/form-control-dependency/form-control-dependency-mixin";

/**
 x - Zeitlichen Vorlauf (Puffer, TimeGap) - nächstmöglicher Beginn
 x - also bspw. der Kunde füllt um 10:45 Uhr das RR Formular aus: Als Zeitspanne für einen Rückruf kann 13 Uhr bis 17 Uhr gewählt werden.
 x - Die Rückrufzeitspanne soll automatisch bis Ende Servicezeit vorausgefüllt sein, Montag - Donnerstag 17 Uhr, Freitag 15 Uhr (z.B. Dienstag Rückrufbeginn 10 Uhr, dann Rückrufende auf 17 Uhr vorausgefüllt).
 x - Der Kunde soll manuell die Möglichkeit haben diese Rückrufspanne verkleinern zu können, jedoch Minimum auf eine Zeitspanne von 2 Std. (z.B. Dienstag Rückrufbeginn 10 Uhr, dann Minimum Rückrufende 12 Uhr).
 x - Samstage und Sonntage sollen im Frontend nicht auswählbar sein.
 x - Montag - Donnerstag ab 13 Uhr, Freitag ab 11 Uhr ist der Rückruf nur für den nächsten Werktag einstellbar, nicht mehr für den aktuellen Tag.
 x - Alle oben genannten Funktionalitäten sollen auch bei manueller Eingabe des Datums funktionieren
 */

type PossibleOpeningHours = {
    key: number;
    label: string;
    value: string;
}

type SelectOption = {
    value: string;
    label: string;
    description?: string;
    selected?: boolean;
}

type DatepickerObject = {
    detail: { value: string },
    bubbles: boolean,
    composed: boolean
}

@customElement('sbk-callback-with-time-interval')
export class SbkCallbackwithinterval extends FormControlDependencyMixin(FormValidationMixin(SbkLabelMixin(LitElement))) {

    @queryAssignedElements({slot: 'callbackDayInput'})
    _callbackDayInput!: Array<SbkCallbackwithinterval>;
    callbackDayInputValue: Date = new Date();

    @queryAssignedElements({slot: 'startTimeInput'})
    _startTimeInput!: Array<SbkCallbackwithinterval>;
    startTimeInputValue: string = '';

    @queryAssignedElements({slot: 'stopTimeInput'})
    _stopTimeInput!: Array<SbkCallbackwithinterval>;
    stopTimeInputValue: string = '';

    @property({attribute: 'start-monday-until-thursday'})
    startMondayUntilThursday: string = ''

    @property({attribute: 'stop-monday-until-thursday'})
    stopMondayUntilThursday: string = ''

    @property({attribute: 'start-friday'})
    startFriday: string = ''

    @property({attribute: 'stop-friday'})
    stopFriday: string = ''

    @property({attribute: 'time-gap'})
    timeGap: string = ''

    @property({attribute: 'please-choose-option-label'})
    pleaseChooseOptionLabel: string = ''

    @property({attribute: 'no-appointment-option-label'})
    noAppointmentOptionLabel: string = ''

    @property({attribute: 'no-appointment-label'})
    noAppointmentLabel: string = ''

    @property({attribute: 'day-on-weekend-label'})
    dayOnWeekendLabel: string = ''

    @property({attribute: 'day-in-past-label'})
    dayInPastLabel: string = ''

    @property({attribute: 'day-in-future-label'})
    dayInFutureLabel: string = ''

    configuredStartTime: string = ''
    configuredStopTime: string = ''

    invalid = false;
    description = ''

    datepickerValue = ''
    options: SelectOption[] = [];

    // Example: 19.02.2025, 09:00 Uhr bis 17:00 Uhr
    submittedValueRegex: RegExp = /(\d{2}\.\d{2}\.\d{4}), (\d{2}:\d{2}).+(\d{2}:\d{2})/;
    submittedValueExec: RegExpExecArray|null = null

    possibleOpeningHours: PossibleOpeningHours[] = [
        {key: 0, value: "''", label: this.pleaseChooseOptionLabel},
        {key: 1, value: "6", label: "06:00"},
        {key: 2, value: "6.5", label: "06:30"},
        {key: 3, value: "7", label: "07:00"},
        {key: 4, value: "7.5", label: "07:30"},
        {key: 5, value: "8", label: "08:00"},
        {key: 6, value: "8.5", label: "08:30"},
        {key: 7, value: "9", label: "09:00"},
        {key: 8, value: "9.5", label: "09:30"},
        {key: 9, value: "10", label: "10:00"},
        {key: 10, value: "10.5", label: "10:30"},
        {key: 11, value: "11", label: "11:00"},
        {key: 12, value: "11.5", label: "11:30"},
        {key: 13, value: "12", label: "12:00"},
        {key: 14, value: "12.5", label: "12:30"},
        {key: 15, value: "13", label: "13:00"},
        {key: 16, value: "13.5", label: "13:30"},
        {key: 17, value: "14", label: "14:00"},
        {key: 18, value: "14.5", label: "14:30"},
        {key: 19, value: "15", label: "15:00"},
        {key: 20, value: "15.5", label: "15:30"},
        {key: 21, value: "16", label: "16:00"},
        {key: 22, value: "16.5", label: "16:30"},
        {key: 23, value: "17", label: "17:00"},
        {key: 24, value: "17.5", label: "17:30"},
        {key: 25, value: "18", label: "18:00"},
        {key: 26, value: "18.5", label: "18:30"},
        {key: 27, value: "19", label: "19:00"},
        {key: 28, value: "19.5", label: "19:30"},
        {key: 29, value: "20", label: "20:00"}
    ];

    render() {
        return html`
            <slot @datepicker-change=${this._onCallbackDayInputChange} name="callbackDayInput"></slot>
            <slot @select-value-changed=${this._onStartTimeInputChange} name="startTimeInput"></slot>
            <slot @slotchange=${this._initCall} @select-value-changed=${this._onStopTimeInputChange}
                  name="stopTimeInput"></slot>
        `;
    }

    _initCall() {

        this._callbackDayInput[0].addEventListener('focusout', () => {
            const datepickerObject: DatepickerObject = {
                detail: {value: this._callbackDayInput[0].datepickerValue},
                bubbles: true,
                composed: true
            };

            this._onCallbackDayInputChange(datepickerObject);
        });

        this._callbackDayInput[0].addEventListener('paste', (e: ClipboardEvent) => {
            e.stopPropagation();
            e.preventDefault();
            let pastedData = '';

            if (e.clipboardData != undefined) {
                pastedData = e.clipboardData.getData('text');
            }

            this._callbackDayInput[0].datepickerValue = pastedData;

            const datepickerObject: DatepickerObject = {
                detail: {value: pastedData},
                bubbles: true,
                composed: true
            };

            this._onCallbackDayInputChange(datepickerObject);
        });

        this._readSubmittedValue()
        if (this.submittedValueExec && typeof this.submittedValueExec[1] !== 'undefined') {
            this._callbackDayInput[0].datepickerValue = this.submittedValueExec[1]
        }
    }

    _readSubmittedValue() {
        const hiddenFields: HTMLInputElement[] = Array.from(this.querySelectorAll('input[type="hidden"]'))
        const firstHiddenField: HTMLInputElement = hiddenFields[0]
        this.submittedValueExec = this.submittedValueRegex.exec(firstHiddenField.value)
    }

    _determineTimes(selectedDay: Date) {

        // friday
        if (selectedDay.getDay() === 5) {
            this.configuredStartTime = this.startFriday;
            this.configuredStopTime = this.stopFriday;
        } else {
            // monday to thursday
            this.configuredStartTime = this.startMondayUntilThursday;
            this.configuredStopTime = this.stopMondayUntilThursday;
        }
    }

    _onCallbackDayInputChange(e: DatepickerObject) {
        this.callbackDayInputValue = this._createDateFromString(e.detail.value);

        this._determineTimes(this.callbackDayInputValue);

        this._fillFromSelectOptions();
    }

    _onStartTimeInputChange(e: CustomEvent) {
        // reset errors
        this._startTimeInput[0].invalid = false;
        this._stopTimeInput[0].invalid = false;

        this.startTimeInputValue = e.detail.value;

        this._fillToSelectOptions(this.startTimeInputValue);
    }

    _onStopTimeInputChange(e: CustomEvent) {
        this.stopTimeInputValue = e.detail.value;
    }

    _fillFromSelectOptions() {

        let started = false;
        let stopped = false;
        let selected = false;

        const options: SelectOption[] = [];

        const optionsValue: SelectOption = {
            label: this.pleaseChooseOptionLabel,
            value: '',
            selected: true
        };
        options.push(optionsValue);

        for (const entry of this.possibleOpeningHours) {
            if (parseFloat(entry.value) >= parseFloat(this.configuredStartTime)) {
                if (this._isToday(this.callbackDayInputValue)) {
                    // if today, do not allow starttime in the past or before the timeGap
                    const currentTimePlusTimeGap = this._getCurrentTimeInHalfHours() + parseFloat(this.timeGap);
                    if (currentTimePlusTimeGap <= parseFloat(entry.value)) {
                        started = true;
                    }
                } else {
                    started = true;
                }
            }

            if (started && !stopped) {
                selected = false;
                if (this.submittedValueExec && typeof this.submittedValueExec[2] !== 'undefined') {
                    selected = entry.label === this.submittedValueExec[2]
                }
                const optionsValue: SelectOption = {
                    label: entry.label,
                    value: entry.value,
                    selected: selected
                };
                options.push(optionsValue);
            }

            if (parseFloat(this.configuredStopTime) - parseFloat(this.configuredStartTime) <= parseFloat(this.timeGap)) {
                // opening hours are too little for timeGap
                if (parseFloat(entry.value) == parseFloat(this.configuredStopTime)) {
                    const lastEntry = options[options.length - 1];
                    if (lastEntry.label != this.pleaseChooseOptionLabel) {
                        // delete last entry
                        options.pop();
                    }
                    stopped = true;
                }
            } else {
                if (parseFloat(entry.value) == (parseFloat(this.configuredStopTime) - parseFloat(this.timeGap))) {
                    stopped = true;
                }
            }
        }

        // no appointments available
        if (options.length === 1) {
            this._callbackDayInput[0].annotation = this.noAppointmentLabel;
            this._callbackDayInput[0].invalid = true;
            this._setNoAppointmentsAvailable();
            return;
        }

        this._startTimeInput[0].options = options;
    }

    _fillToSelectOptions(selectedTime: string) {
        let started = false;
        let stopped = false;
        let selected = false;
        let selectedOptionExists = false;

        const options: SelectOption[] = [];

        const optionsValue: SelectOption = {
            label: this.pleaseChooseOptionLabel,
            value: '',
            selected: false
        };
        options.push(optionsValue);

        for (const entry of this.possibleOpeningHours) {

            if (parseFloat(this.configuredStopTime) - parseFloat(this.configuredStartTime) <= parseFloat(this.timeGap)) {
                // opening hours are too little for timeGap
                if (parseFloat(entry.value) >= parseFloat(selectedTime)) {
                    started = true;
                }
            } else {
                if (parseFloat(entry.value) >= (parseFloat(selectedTime) + parseFloat(this.timeGap))) {
                    started = true;
                }
            }

            if (started && !stopped) {
                selected = false;
                if (this.submittedValueExec && typeof this.submittedValueExec[3] !== 'undefined') {
                    selected = entry.label === this.submittedValueExec[3]
                }
                if (selected) {
                    selectedOptionExists = true;
                }
                const optionsValue: SelectOption = {
                    label: entry.label,
                    value: entry.value,
                    selected: selected
                };
                options.push(optionsValue);
            }

            if (parseFloat(entry.value) >= parseFloat(this.configuredStopTime)) {
                stopped = true;

                // select last entry
                if (options.length > 0 && !selectedOptionExists) {
                    options[options.length - 1].selected = true;
                }
            }
        }

        if (parseFloat(this.configuredStopTime) - parseFloat(this.configuredStartTime) <= parseFloat(this.timeGap)) {
            // opening hours are too little for timeGap
            // delete second entry
            options.splice(1, 1);
        }

        // no appointments available
        if (options.length === 1) {
            if (this._startTimeInput[0].options[0].label == this.noAppointmentOptionLabel) {
                options[0].label = this.noAppointmentOptionLabel;
                options[0].value = '';
            }
        }

        this._stopTimeInput[0].options = options;
    }

    _setNoAppointmentsAvailable() {
        const options: SelectOption[] = [];
        const optionsValue: SelectOption = {
            label: this.noAppointmentOptionLabel,
            value: '',
            selected: true
        };
        options.push(optionsValue);

        this._startTimeInput[0].options = options;
        this._stopTimeInput[0].options = options;
    }

    _createDateFromString(dateString: string): Date {
        const [day, month, year] = dateString.split('.').map(part => parseInt(part, 10));

        return new Date(year, month - 1, day);
    }

    _getCurrentTimeInHalfHours(): number {
        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();

        if (minutes >= 30) {
            return hours + 1;
        }

        if (minutes >= 1) {
            return hours + 0.5;
        } else {
            return hours;
        }
    }

    _isToday(date: Date): boolean {
        const today = new Date();

        const todayYear = today.getFullYear();
        const todayMonth = today.getMonth(); // Monate sind 0-basiert (0 = Januar, 11 = Dezember)
        const todayDay = today.getDate();

        const dateYear = date.getFullYear();
        const dateMonth = date.getMonth();
        const dateDay = date.getDate();

        // Check whether year, month and day match
        return todayYear === dateYear && todayMonth === dateMonth && todayDay === dateDay;
    }
}
